/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onServiceWorkerUpdateReady = () => {
  // const answer = window.confirm(
  //   `Die Webseite wurde aktualisiert. ` +
  //     `Neu laden um die aktuellste Version anzuzeigen?`
  // )

  // if (answer === true) {
  window.location.reload()
  // }
}

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `Die Webseite wurde aktualisiert. ` +
//       `Neu laden um die aktuellste Version anzuzeigen?`
//   )

//   if (answer === true) {
//     window.location.reload()
//   }
// }

// You can delete this file if you're not using it
